import React from "react";
import Login from "./components/Login";
import { Header } from "./components/Header";
import { Sidebar } from "./components/Sidebar";
import { Users } from "./views/users";
import { Customers } from "./views/customers";
import { NotificationContainer } from "react-notifications";
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <Login>
      <NotificationContainer />
      <Router>
        <div className="App">
          <Header />
          <Sidebar />
          <div className="main wide">
            <div className="container">
              <Route exact path="/users" component={Users} />
              <Route exact path="/customers" component={Customers} />
              <Route exact path="/" component={Users} />
            </div>
          </div>
        </div>
      </Router>
    </Login>
  );
}

export default App;
