export const addUserPermissions = `mutation addUserPermissions($input:AddUserPermissionsInput!){
    addUserPermissions(input:$input){
        email
    }
}`;

export const createNewUser = `mutation createNewUser($input:NewUserInput!){
  createUser(input:$input){
    email
  }
}`;

export const removeUserPermissions = `mutation removeUserPermissions($input: RemoveUserPermissionsInput!){
    removeUserPermissions(input:$input){
        email
    }
}`;

export const setModule = `mutation setModule($input:CustomerModuleInput!){
  setCustomerModule(input:$input){
    id
  }
}`;

export const setAutobackup = `mutation setAutobackupV2Settings($input: AutobackupV2AppSettingsInput!){
    setAutobackupV2Settings(input: $input)
}`

export const createAPIKey = `mutation createAPIKey($email: String!, $description: String){
    createAPIKey(email: $email, description: $description){
        id
        description
        secret
    }
}`;

export const removeAPIKey = `mutation removeAPIKey($keyId: String!){
    removeAPIKey(keyId:$keyId)
}`;

export const addKeyPermissions = `mutation addKeyPermissions($keyId: String!, $input: AddKeyPermissionsInput!){
    addKeyPermissions(keyId: $keyId, input: $input){
        id
    }
}`;

export const removeKeyPermissions = `mutation removeKeyPermissions($keyId: String!, $input: RemoveKeyPermissionsInput!){
    removeKeyPermissions(keyId: $keyId, input: $input){
        id
    }
}`;
