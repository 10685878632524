import React from "react";
import { withOAuth } from "aws-amplify-react";
import { Auth, Hub, Logger } from "aws-amplify";

class Login extends React.Component {
  constructor() {
    super();
    this.state = { loggedIn: false };
  }
  checkCurrentUser = () => {
    // const { OAuthSignIn } = this.props;
    const hasAuthCode = window.location.search.substr(1).indexOf("code") > -1;

    Auth.currentAuthenticatedUser()
      .then(user => this.setState({ loggedIn: true }))
      .catch(e => {
        if (hasAuthCode) {
          const alex = new Logger("Alexander_the_auth_watcher");
          alex.onHubCapsule = capsule => {
            switch (capsule.payload.event) {
              case "signIn":
                this.setState({ loggedIn: true });
                break;
              case "configured":
                alex.error("the Auth module is configured");
                break;
              default:
                break;
            }
          };
          Hub.listen("auth", alex);
        } else {
          this.props.OAuthSignIn();
        }
      });
  };
  componentDidMount() {
    this.checkCurrentUser();
  }
  render() {
    const { children } = this.props;
    const { loggedIn } = this.state;
    return loggedIn ? children : <div />;
  }
}

export default withOAuth(Login);
