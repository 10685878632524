import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/index.scss";
import "react-notifications/lib/notifications.css";
import "font-awesome/css/font-awesome.css";
import App from "./App";

import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,
      scope: ["email"],
      redirectSignIn: process.env.REACT_APP_REDIRECT,
      redirectSignOut: process.env.REACT_APP_REDIRECT,
      responseType: "code",
      options: {
        AdvancedSecurityDataCollectionFlag: true
      }
    }
  },
  API: {
    graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    graphql_headers: async () => ({
      Authorization: await Auth.currentSession().then(user =>
        user.getIdToken().getJwtToken()
      )
    })
  }
});

ReactDOM.render(<App />, document.getElementById("root"));
