import React, { useState } from "react";
import { CustomersList } from "./CustomersList";
import { Customer } from "./Customer";

export const Customers = () => {
  const [customer, setCustomer] = useState();
  return (
    <>
      <h1 className="title">Customers</h1>
      <h1 className="subtitle">Select customer to set applications</h1>
      <CustomersList
        value={customer}
        set={e => {
          setCustomer(e);
        }}
      />

      {customer && <Customer id={customer.value} />}
    </>
  );
};
