import React, { useState } from "react";

import Connect from "../../components/Connect";
import { graphqlOperation } from "aws-amplify";
import {
  createAPIKey,
  removeAPIKey,
  addKeyPermissions,
  removeKeyPermissions
} from "../../graphql/mutations";
import { NotificationManager } from "react-notifications";
import { roles } from "./User";
import { Role } from "../../components/Role";
import Reveal from '../../components/Reveal';
import Select from "react-select";


const DeletePermission = ({ keyId, values, reload }) => {
  const confirmAndDelete = mutation => async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete API Key ${keyId}?`
    );
    const input = {
      keyId: keyId,
      input: values
    };

    if (confirmed) {
      try {
        await mutation(input);
      } catch (e) {
        NotificationManager.error(e.errors[0].message);
      }
      reload();
    }
  };
  return (
    <Connect mutation={graphqlOperation(removeKeyPermissions)}>
      {({ loading, mutation }) => (
        <button className="button" onClick={confirmAndDelete(mutation)}>
          Delete
        </button>
      )}
    </Connect>
  );
};

const AddPermission = ({ type, options, reload, keyId }) => {
  const [popUp, setPopUp] = useState(false);
  const [role, setRole] = useState(roles[0]);
  const [keyVal, setKeyVal] = useState({});

  const addNewPermission = mutation => async () => {
    const input = {
      keyId: keyId,
      input: {
        role: role,
        customerRoles:
          type === "Customer" ? [{ role: role, customerId: keyVal.value }] : [],
        applicationRoles:
          type === "Application"
            ? [{ role: role, applicationName: keyVal.value }]
            : []
      }
    };

    try {
      await mutation(input);
    } catch (e) {
      NotificationManager.error(e.errors[0].message);
    }
    reload();
  };

  return (
    <Connect mutation={graphqlOperation(addKeyPermissions)}>
      {({ loading, mutation }) =>
        popUp ? (
          <div class="modal is-active">
            <div class="modal-background" />
            <div class="modal-content" style={{overflow: "visible"}}>
              <div class="inner">
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>{type} name</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="field">
                          <Select
                            className="basic-single is-pulled-left "
                            classNamePrefix="select"
                            isSearchable={true}
                            options={options.map(o => ({
                              value: o.value,
                              label: o.label ? o.label : o,
                            }))}
                            value={keyVal}
                            onChange={e => setKeyVal(e)}
                          />
                        </div>
                      </td>
                      <td>
                        <div class="select">
                          <select onChange={e => setRole(e.target.value)}>
                            {roles.map(role => (
                              <option>{role}</option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button className={"button"} onClick={addNewPermission(mutation)}>
                Confirm
              </button>
            </div>

            <button
              class="modal-close is-large"
              aria-label="close"
              onClick={() => setPopUp(false)}
            />
          </div>
        ) : (
          <button className="button" onClick={() => setPopUp(true)}>
            Add permission
          </button>
        )
      }
    </Connect>
  );
};

const KeyDetails = ({ details, reload, close, customers, applications }) => (
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">Key {details.id}</p>
        <button className="modal-close-btn" onClick={() => close()}>
          <i className="fa fa-times"></i>
        </button>
      </header>
      <div className="box">
        <div className="has-table">
          <h1 className="subtitle">Application Roles</h1>
          {details &&
          details.access &&
          details.access.applicationRoles &&
          details.access.applicationRoles.length > 0 ? (
            <table className="table is-striped">
              <thead>
                <tr>
                  <th>App name</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {details.access.applicationRoles.map(
                  ({ role, applicationName }) => (
                    <tr>
                      <td>{applicationName}</td>
                      <td>
                        <Role role={role} />
                      </td>
                      <td>
                        <DeletePermission
                          reload={reload}
                          keyId={details.id}
                          values={{ applicationsToDelete: [applicationName] }}
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ) : (
            <i>This key has no application specific roles</i>
          )}
          <AddPermission
            type={"Application"}
            reload={reload}
            options={applications}
            keyId={details.id}
          />
        </div>
        <div className="has-table">
          <h1 className="subtitle">Customer Roles</h1>
          {details &&
          details.access &&
          details.access.customerRoles &&
          details.access.customerRoles.length > 0 ? (
            <table className="table is-striped">
              <thead>
                <tr>
                  <th>Customer id</th>
                  <th>Role</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {details.access.customerRoles.map(({ role, customerId }) => (
                  <tr>
                    <td>{customerId}</td>
                    <td>
                      <Role role={role} />
                    </td>
                    <td>
                      <DeletePermission
                        reload={reload}
                        keyId={details.id}
                        values={{ customersToDelete: [customerId] }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <i>This key has no customer specific roles</i>
          )}
          <AddPermission
            type={"Customer"}
            reload={reload}
            options={customers}
            keyId={details.id}
          />
        </div>
      </div>
    </div>
  </div>
);

const DeleteApiKey = ({ keyId, reload }) => {
  const confirmAndDelete = mutation => async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete API Key ${keyId}?`
    );

    if (confirmed) {
      try {
        await mutation({ keyId: keyId });
      } catch (e) {
        NotificationManager.error(e.errors[0].message);
      }
      NotificationManager.success("API Key successfully deleted.");
      reload();
    }
  };
  return (
    <Connect mutation={graphqlOperation(removeAPIKey)}>
      {({ mutation }) => (
        <button className="button" onClick={confirmAndDelete(mutation)}>
          DELETE
        </button>
      )}
    </Connect>
  );
};

const CreateApiKey = ({ email, reload, prevKeys, setKeys }) => {
  const confirmAndCreate = mutation => async () => {
    const description = window.prompt(`Type in an API Key description.`);

    if (description) {
      let newKeyCreated;
      try {
        newKeyCreated = await mutation({ email: email, description: description });
      } catch (e) {
        NotificationManager.error(e.errors[0].message);
      }
      if (newKeyCreated.data && newKeyCreated.data.createAPIKey) {
          let newKey = newKeyCreated.data.createAPIKey;
          newKey.unencrypted = true;
          setKeys([newKeyCreated.data.createAPIKey, ...prevKeys]);
      }
      NotificationManager.success("API Key successfully created. Remember to copy the secret.");
    }
  };
  return (
    <Connect mutation={graphqlOperation(createAPIKey)}>
      {({ mutation }) => (
        <button className="button" onClick={confirmAndCreate(mutation)}>
          Create new key
        </button>
      )}
    </Connect>
  );
};

export const ApiKeys = ({ keys, reload, email, customers, applications }) => {
  const [details, setDetails] = useState(false);
  const [keyList, setKeyList] = useState(keys.length > 0 ? keys : []);
  return (
    <>
      {details && (
        <KeyDetails
          details={details}
          reload={reload}
          customers={customers}
          applications={applications}
          close={() => setDetails(false)}
        />
      )}
      {keyList.length > 0 ? (
        <table className="table is-striped">
          <thead>
            <tr>
              <th>Key ID</th>
              <th>Secret</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {keyList.map(key => (
              <tr key={key.id}>
                <td>{key.id}</td>
                <td>{key.unencrypted ? (<Reveal text={key.secret} />) : (<em>***</em>)}</td>
                <td>{key.description}</td>
                <td>
                  <button className="button" onClick={() => setDetails(key)}>
                    PERMISSIONS
                  </button>
                  <DeleteApiKey keyId={key.id} reload={reload} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <i>This users has no API Keys</i>
      )}
      <CreateApiKey email={email} reload={reload} setKeys={setKeyList} prevKeys={keyList} />
    </>
  );
};
