import React from "react";

import Connect from "../../components/Connect";
import { graphqlOperation } from "aws-amplify";
import { getCMPUsers } from "../../graphql/queries";
import { createNewUser } from "../../graphql/mutations";
import Select from "react-select";
import { Error } from "../../components/Error";
import { NotificationManager } from "react-notifications";
import { Loading } from "../../components/Loading";

const CreateUser = ({ reload, set }) => {
  const add = mutation => async () => {
    const email = window.prompt("Please provide email");
    if (email && email !== null) {
      const input = {
        input: {
          email
        }
      };
      try {
        await mutation(input);
        NotificationManager.success(`User ${email} created`);
      } catch (e) {
        NotificationManager.error(e.errors[0].message);
      } finally {
        reload();
      }
    }
  };
  return (
    <Connect mutation={graphqlOperation(createNewUser)}>
      {({ loading, mutation }) => (
        <button className="button is-pulled-right" onClick={add(mutation)}>
          Add new user
        </button>
      )}
    </Connect>
  );
};

export const UsersList = ({ value, set }) => (
  <>
    <Connect query={graphqlOperation(getCMPUsers)}>
      {({ data: { users = [] }, errors, forceUpdate, loading }) => (
        <>
          <h1 className="title">User Permissions Manager</h1>
          <h1 className="subtitle">Select user from list or create new</h1>

          <div className="box is-clearfix">
            {errors && errors.map(e => <Error error={e.message} />)}
            {loading && <Loading />}
            {users && users.length > 0 && (
              <Select
                className="basic-single is-pulled-left "
                classNamePrefix="select"
                isSearchable={true}
                name="User"
                options={users.map(({ email }) => ({
                  value: email,
                  label: email
                }))}
                value={value}
                onChange={e => set(e)}
              />
            )}
            <CreateUser reload={forceUpdate} set={set} />
          </div>
        </>
      )}
    </Connect>
  </>
);
