import React, { useState } from "react";

import Connect from "../../components/Connect";
import { graphqlOperation } from "aws-amplify";
import { getCMPUser } from "../../graphql/queries";
import {
  removeUserPermissions,
  addUserPermissions
} from "../../graphql/mutations";
import Select from "react-select";
import { Error } from "../../components/Error";
import { Role } from "../../components/Role";
import { NotificationManager } from "react-notifications";
import { Loading } from "../../components/Loading";
import { ApiKeys } from "./ApiKeys";

export const roles = [`READ_ONLY`, `ADMIN`, `SUPER_ADMIN`];

const DeletePermission = ({ type, value, reload, email, global, label }) => {
  const [error, setError] = useState("");
  const confirmAndDelete = mutation => async () => {
    const confirmed = window.confirm(
      `Are you sure you want to delete permissions for ${(label
        ? label
        : value) || `global CMP scope`}?`
    );
    if (confirmed) {
      setError("");
      const input = {
        input: {
          ...(value ? { [type]: [value] } : {}),
          ...(global ? { deleteGlobalRole: true } : {}),
          email
        }
      };
      try {
        await mutation(input);
      } catch (e) {
        NotificationManager.error(e.errors[0].message);
      }
      reload();
    }
  };
  return (
    <Connect mutation={graphqlOperation(removeUserPermissions)}>
      {({ loading, mutation, errors }) => (
        <>
          {error && error.length > 0 && <Error error={error} />}
          <button className="button" onClick={confirmAndDelete(mutation)}>
            Delete
          </button>
        </>
      )}
    </Connect>
  );
};

const AddPermission = ({
  type,
  label,
  reload,
  email,
  global,
  keyName,
  options = []
}) => {
  const [popup, setPopup] = useState(false);
  const [role, setRole] = useState(roles[0]);
  const [keyVal, setKeyVal] = useState({});
  const [error, setError] = useState("");

  const add = ({ mutation, role }) => async () => {
    setError(false);
    const input = {
      input: {
        access: {
          ...(type ? { [type]: { [keyName]: keyVal.value, role } } : {}),
          ...(global ? { role: role } : {})
        },
        email
      }
    };
    try {
      await mutation(input);
    } catch (e) {
      NotificationManager.error(e.errors[0].message);
    } finally {
      setPopup(false);
      reload();
    }
  };

  return (
    <Connect mutation={graphqlOperation(addUserPermissions)}>
      {({ loading, mutation }) =>
        popup ? (
          <div class="modal is-active">
            <div class="modal-background" />
            <div class="modal-content" style={{overflow: "visible"}}>
              <div class="inner">
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>{label}</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {!global && options.length === 0 && (
                          <input
                            type="text"
                            value={keyVal.value}
                            onChange={e => setKeyVal({value: e.target.value})}
                          />
                        )}
                        {!global && options.length > 0 && (
                          <div className="field">
                            <Select
                              className="basic-single is-pulled-left "
                              classNamePrefix="select"
                              isSearchable={true}
                              options={options.map(o => ({
                                value: o.value,
                                label: o.label ? o.label : o,
                              }))}
                              value={keyVal}
                              onChange={e => setKeyVal(e)}
                            />
                          </div>
                        )}
                        {global && <span>Global role</span>}
                      </td>
                      <td>
                        <div class="select">
                          <select onChange={e => setRole(e.target.value)}>
                            {roles.map(role => (
                              <option>{role}</option>
                            ))}
                          </select>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button className={"button"} onClick={add({ mutation, role })}>
                Confirm
              </button>
            </div>

            <button
              class="modal-close is-large"
              aria-label="close"
              onClick={() => setPopup(false)}
            />
          </div>
        ) : (
          <>
            {error && error.length > 0 && <Error error={error} />}
            <button className="button" onClick={() => setPopup(true)}>
              Add permission
            </button>
          </>
        )
      }
    </Connect>
  );
};

export const User = ({ email }) => (
  <div>
    <Connect query={graphqlOperation(getCMPUser, { email: email })}>
      {({ data: { user, modules, customers = [] }, forceUpdate, loading }) =>
        loading ? (
          <Loading />
        ) : user ? (
          <>
            <h1 className="subtitle">Global role</h1>
            <div className="box">
              <Role role={user.access.role} />
              {user.access.role && (
                <DeletePermission
                  global={true}
                  reload={forceUpdate}
                  email={email}
                />
              )}
              {!user.access.role && (
                <AddPermission
                  global={true}
                  reload={forceUpdate}
                  email={email}
                />
              )}
            </div>

            <div className="has-table">
              <h1 className="subtitle">Applications</h1>
              <div className="box">
                {user.access.applicationRoles.length > 0 ? (
                  <table className="table is-striped">
                    <thead>
                      <tr>
                        <th>App name</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.access.applicationRoles.map(
                        ({ role, applicationName }) => (
                          <tr>
                            <td>
                              {modules.find(m => m.name === applicationName)
                                ? modules.find(m => m.name === applicationName)
                                    .label
                                : applicationName}
                            </td>
                            <td>
                              <Role role={role} />
                            </td>
                            <td>
                              <DeletePermission
                                value={applicationName}
                                type={"applicationsToDelete"}
                                reload={forceUpdate}
                                email={email}
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : (
                  <i>This users has no application specific roles</i>
                )}
                <AddPermission
                  type={`applicationRoles`}
                  keyName={`applicationName`}
                  reload={forceUpdate}
                  email={email}
                  label={"Application name:"}
                  options={modules.map(m => ({
                    value: m.name,
                    label: m.label
                  }))}
                />
              </div>
            </div>
            <div className="has-table">
              <h1 className="subtitle">Accounts</h1>
              <div className="box">
                {user.access.accountRoles.length > 0 ? (
                  <table className="table is-striped">
                    <thead>
                      <tr>
                        <th>Account ID</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.access.accountRoles.map(({ role, accountId }) => (
                        <tr>
                          <td>{accountId}</td>
                          <td>
                            <Role role={role} />
                          </td>
                          <td>
                            {" "}
                            <DeletePermission
                              value={accountId}
                              type={"accountsToDelete"}
                              reload={forceUpdate}
                              email={email}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <i>This users has no account specific roles</i>
                )}

                <AddPermission
                  type={`accountRoles`}
                  keyName={`accountId`}
                  reload={forceUpdate}
                  email={email}
                  label={"Account Id:"}
                />
              </div>
            </div>
            <div className="has-table">
              <h1 className="subtitle">Customers</h1>
              <div className="box">
                {user.access.customerRoles.length > 0 ? (
                  <table className="table is-striped">
                    <thead>
                      <tr>
                        <th>Customer ID</th>
                        <th>Role</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.access.customerRoles.map(({ role, customerId }) => (
                        <tr>
                          <td>
                            {customerId &&
                            customers.find(({ id }) => id === customerId)
                              ? customers.find(({ id }) => id === customerId)
                                  .name
                              : customerId}
                          </td>
                          <td>
                            <Role role={role} />
                          </td>
                          <td>
                            <DeletePermission
                              value={customerId}
                              label={
                                customerId &&
                                customers.find(({ id }) => id === customerId)
                                  ? customers.find(
                                      ({ id }) => id === customerId
                                    ).name
                                  : customerId
                              }
                              type={"customersToDelete"}
                              reload={forceUpdate}
                              email={email}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <i>This users has no account specific roles</i>
                )}
                <AddPermission
                  type={`customerRoles`}
                  keyName={`customerId`}
                  reload={forceUpdate}
                  email={email}
                  label={"Customer Name:"}
                  options={customers.map(({ name, id }) => ({
                    label: name,
                    value: id
                  }))}
                />
              </div>
            </div>
            <div className="has-table">
              <h1 className="subtitle">API Keys</h1>
              <div className="box is-clearfix">
                {user.apiKeys && (
                  <ApiKeys
                    keys={user.apiKeys}
                    reload={forceUpdate}
                    email={email}
                    customers={customers.map(({ name, id }) => ({
                      label: name,
                      value: id
                    }))}
                    applications={modules.map(m => ({
                      value: m.name,
                      label: m.label
                    }))}
                  />
                )}
              </div>
            </div>
          </>
        ) : null
      }
    </Connect>
  </div>
);
