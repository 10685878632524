import React, { useState } from 'react';


const Reveal = ({ text }) => {
  const [isHidden, setIsHidden] = useState(true);
  const buttonText = isHidden ? 'Show' : 'Hide';
  const revealText = isHidden ? '*'.repeat(text.length) : text;

  const toggle = () => {
    setIsHidden(!isHidden);
  }

  return (
    <div className="reveal">
      <span>{revealText}</span><button className="button" onClick={toggle}>{buttonText}</button>
      <br/><small>Be careful, save this secret because you can only see it once!</small>
    </div>
  );
};

export default Reveal;
