import React from "react";
import { Link } from "react-router-dom";
export const Sidebar = () => (
  <div className="sidebar">
    <nav class="navbar-static-side" role="navigation">
      <ul class="nav" id="side-menu">
        <li class="">
          <Link to="/users">
            <i class="fa fa-user" />
            <span class="nav-label">Users</span>
            <span class="fa arrow" />
          </Link>
        </li>
        <li class="">
          <Link to="/customers">
            <i class="fa fa-building" />
            <span class="nav-label">Customers</span>
            <span class="fa arrow" />
          </Link>
        </li>
      </ul>
    </nav>
  </div>
);
