import React from "react";

import Connect from "../../components/Connect";
import { graphqlOperation } from "aws-amplify";
import { getCMPCustomers } from "../../graphql/queries";
import Select from "react-select";
import { Error } from "../../components/Error";

export const CustomersList = ({ value, set }) => {
  return (
    <>
      <Connect query={graphqlOperation(getCMPCustomers)}>
        {({ data: { customers = [] }, errors, forceUpdate }) => (
          <div className="box is-clearfix">
            {errors && errors.map(e => <Error error={e.message} />)}
            {customers && customers.length > 0 && (
              <Select
                className="basic-single is-pulled-left "
                classNamePrefix="select"
                isSearchable={true}
                name="User"
                options={customers.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
                value={value}
                onChange={e => set(e)}
              />
            )}
          </div>
        )}
      </Connect>
    </>
  );
};
