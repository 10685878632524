export const getCMPUsers = `query getCMPUsers{
    users{
        email,
  }
}`;

export const getCMPUser = `query getCMPUser($email: String!){
  user(email: $email){
    access{
      role
      accountRoles{
        accountId
        role
      }
      customerRoles{
        role
        customerId
      }
      applicationRoles{
        role,
          applicationName
      }
    }
    apiKeys {
      id
      description
      secret
      updatedAt
      createdAt
      access {
        role
        customerRoles{
          role
          customerId
        }
        applicationRoles{
          role,
            applicationName
        }
      }
    }
  }
  modules{
    name,
    label
  }
  customers{
    name,
    id
  }
}`;

export const getCMPCustomers = `query getCustomers{
  customers{
    name,
    id
  }
}`;

export const getCMPCustomer = `query getCustomer($id: String!){
  customer(customerId: $id){
    name,
    id,
    modules{
      name,
      enabled
    }
  }
  modules{
    name,
    label
  }
}`;
