import React, { useState } from "react";
import { UsersList } from "./UsersList";
import { User } from "./User";

export const Users = () => {
  const [email, setEmail] = useState("");

  return (
    <>
      <UsersList
        value={{ value: email, label: email }}
        set={e => {
          setEmail(e.value);
        }}
      />
      {email.length > 0 && <User email={email} />}
    </>
  );
};
