import React, { useState } from "react";

import Connect from "../../components/Connect";
import { graphqlOperation } from "aws-amplify";
import { getCMPCustomer } from "../../graphql/queries";
import { setModule, setAutobackup } from "../../graphql/mutations";
import { NotificationManager } from "react-notifications";
import { LoadingHOC } from "../../components/Loading";

export const Customer = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [rpo, setRpo] = useState();
  const [
    primarySnapshotRetentionQuantity,
    setPrimarySnapshotRetentionQuantity,
  ] = useState();
  const [
    primarySnapshotRetentionDays,
    setPrimarySnapshotRetentionDays,
  ] = useState();
  const [
    replicaSnapshotRetentionQuantity,
    setReplicaSnapshotRetentionQuantity,
  ] = useState();
  const [
    replicaSnapshotRetentionDays,
    setReplicaSnapshotRetentionDays,
  ] = useState();

  const set = (mutation, name, enabled, reload) => async () => {
    const input = {
      input: {
        customerId: id,
        module: {
          name,
          enabled,
        },
      },
    };
    setLoading(true);
    try {
      if (name === "autobackup") {
        const confirmed = window.confirm(
          "Are you sure you want to disable Autobackup v1 for this client? All backups will be disabled."
        );
        if (confirmed) {
          await mutation(input);
        }
      } else {
        await mutation(input);
      }
    } catch (e) {
      NotificationManager.error(e.errors[0].message);
    } finally {
      setLoading(false);
    }
    reload();
  };

  const setAB = (mutation, reload) => async () => {
    const input = {
      input: {
        customerId: id,
        enabled: true,
        minRPOInHours: rpo,
        maxPrimarySnapshotRetentionInDays: primarySnapshotRetentionDays,
        maxPrimarySnapshotRetentionQuantity: primarySnapshotRetentionQuantity,
        maxReplicaSnapshotRetentionInDays: replicaSnapshotRetentionDays,
        maxReplicaSnapshotRetentionQuantity: replicaSnapshotRetentionQuantity,
      },
    };
    setLoading(true);
    try {
      await mutation(input);
    } catch (e) {
      NotificationManager.error(e.errors[0].message);
    } finally {
      setLoading(false);
    }
    setPopup(false);
    reload();
  };

  const promptUserConfirmation = (mutation, reload) => async () => {
    const input = {
      input: {
        customerId: id,
        enabled: false,
        minRPOInHours: null,
        maxPrimarySnapshotRetentionInDays: null,
        maxPrimarySnapshotRetentionQuantity: null,
        maxReplicaSnapshotRetentionInDays: null,
        maxReplicaSnapshotRetentionQuantity: null
      },
    };
    const confirmed = window.confirm(
      "Are you sure you want to disable Autobackup v2 for this client? All backups will be disabled and scheduled events will be cleared."
    );
    if (confirmed) {
      setLoading(true);
      try {
        await mutation(input);
      } catch (e) {
        NotificationManager.error(e.errors[0].message);
      } finally {
        setLoading(false);
      }
      reload();
    }
  };

  return (
    <>
      <h1 className="subtitle">Applications enabled for the customer</h1>

      <div className="box">
        <Connect query={graphqlOperation(getCMPCustomer, { id: id })}>
          {({
            data: { customer = {}, modules = [] },
            loading: queryLoading,
            errors,
            forceUpdate,
          }) => {
            return (
              <LoadingHOC loading={loading || queryLoading}>
                <Connect mutation={graphqlOperation(setAutobackup)}>
                  {({ loading, mutation }) => (
                    <>
                      {popup && (
                        <div class="modal is-active">
                          <div class="modal-background" />
                          <div
                            class="modal-content"
                            style={{ overflow: "visible" }}
                          >
                            <div class="inner">
                              <table className="table is-striped">
                                <thead>
                                  <tr>
                                    <th>
                                      Provide all required settings
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Min RPO (in hours)</td>
                                    <td>
                                      <input
                                        type="text"
                                        value={rpo}
                                        onChange={(e) => setRpo(e.target.value)}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Max primary snapshot retention (quantity)
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        required
                                        value={primarySnapshotRetentionQuantity}
                                        onChange={(e) =>
                                          setPrimarySnapshotRetentionQuantity(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Max primary snapshot retention (days)
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        required
                                        value={primarySnapshotRetentionDays}
                                        onChange={(e) =>
                                          setPrimarySnapshotRetentionDays(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Max replica snapshot retention (quantity)
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={replicaSnapshotRetentionQuantity}
                                        onChange={(e) =>
                                          setReplicaSnapshotRetentionQuantity(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Max replica snapshot retention (days)
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={replicaSnapshotRetentionDays}
                                        onChange={(e) =>
                                          setReplicaSnapshotRetentionDays(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <button
                              className={"button"}
                              onClick={setAB(mutation, forceUpdate)}
                            >
                              Confirm
                            </button>
                          </div>
                          <button
                            class="modal-close is-large"
                            aria-label="close"
                            onClick={() => setPopup(false)}
                          />
                        </div>
                      )}
                    </>
                  )}
                </Connect>
                <table className="table is-fullwidth is-striped">
                  <thead>
                    <tr>
                      <th>Application name</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modules.map(({ name, label }) => (
                      <tr>
                        <td>{label}</td>
                        <td>
                          {customer.modules &&
                          customer.modules.find((m) => m.name === name) &&
                          customer.modules.find((m) => m.name === name)
                            .enabled ? (
                            <span className="tag is-success">Enabled</span>
                          ) : (
                            <span className="tag ">Disabled</span>
                          )}
                        </td>
                        <td>
                          {customer.modules &&
                          customer.modules.find((m) => m.name === name) &&
                          customer.modules.find((m) => m.name === name)
                            .enabled ? (
                            <Connect mutation={graphqlOperation(setModule)}>
                              {({ loading, mutation }) => (
                                <>
                                  {name === "autobackup2" ? (
                                    <Connect
                                      mutation={graphqlOperation(setAutobackup)}
                                    >
                                      {({ loading, mutation }) => (
                                        <button
                                          className={`button `}
                                          onClick={promptUserConfirmation(
                                            mutation,
                                            forceUpdate
                                          )}
                                        >
                                          disable
                                        </button>
                                      )}
                                    </Connect>
                                  ) : (
                                    <button
                                      className={`button `}
                                      onClick={set(
                                        mutation,
                                        name,
                                        false,
                                        forceUpdate
                                      )}
                                    >
                                      disable
                                    </button>
                                  )}
                                </>
                              )}
                            </Connect>
                          ) : (
                            <Connect mutation={graphqlOperation(setModule)}>
                              {({ loading, mutation }) => (
                                <>
                                  {name === "autobackup2" ? (
                                    <button
                                      className={`button`}
                                      onClick={(e) => setPopup(true)}
                                    >
                                      enable
                                    </button>
                                  ) : (
                                    <button
                                      disabled={name === "autobackup"}
                                      className={`button`}
                                      onClick={set(
                                        mutation,
                                        name,
                                        true,
                                        forceUpdate
                                      )}
                                    >
                                      enable
                                    </button>
                                  )}
                                </>
                              )}
                            </Connect>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </LoadingHOC>
            );
          }}
        </Connect>
      </div>
    </>
  );
};
